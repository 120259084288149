import { useCallback } from 'react';
import { Offer } from 'generated/types';
import { getTOfferTypeFromOfferType } from 'common/utils/sdk';
import { useLazyGetOffersQuery } from 'lib/features/offers';
import { OfferType } from '@super-protocol/sdk-js';

export const useBaseOffers = () => {
  const [getOffers, offersResponse] = useLazyGetOffersQuery();
  const getBaseOffersData = useCallback(async (offer: Offer | null | undefined): Promise<{ ids: string[]; data: Offer[]; }> => {
    const defaultValue = { ids: [], data: [] };
    if (!offer) return defaultValue;
    const ids = offer ? offer.offerInfo?.restrictions?.offers : [];
    if (!ids?.length) return defaultValue;
    const offerType = offer.offerInfo?.offerType;
    if (!offerType) return defaultValue;

    const offersResponse = await getOffers({
      filter: { ids, offerType: getTOfferTypeFromOfferType(offerType as OfferType) },
      pagination: { first: null },
    });

    if (offersResponse?.error) {
      throw offersResponse.error;
    }

    const data = offersResponse
      ? (offersResponse?.data?.result?.page?.edges || []).map((edge) => edge?.node as Offer)
      : [];

    return {
      ids,
      data,
    };
  }, [getOffers]);

  return {
    loading: offersResponse?.isLoading,
    getOffers: getBaseOffersData,
  };
};