import { useCallback, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';

import {
  updateOfferInForm as updateOfferInFormAction,
  deleteOfferFromForm as deleteOfferFromFormAction,
  resetSteps as resetStepsAction,
  updateSlotInForm as updateSlotInFormAction,
} from 'lib/features/createOrder';
import { useAppDispatch, useAppSelector } from 'lib/hooks';
import { AttentionAddOrder } from 'common/components/AttentionAddOrder';
import { useLazyGetOfferQuery } from 'lib/features/offers';
import { useLazyGetTeeOfferQuery } from 'lib/features/teeOffers';
import { useExtendedFormOffers } from 'lib/features/createOrder/hooks/useExtendedFormOffers';
import { addErrorNotification } from 'lib/features/notifications';
import { updatePageFilters, filtersSelector } from 'lib/features/filters';
import { Form, FormFiltersCompute, FiltersFields } from 'lib/features/filters/types';
import {
  TeeOffer, Offer, TIdSource, OfferSlot, TeeOfferSlot,
} from 'generated/types';
import { OffersPages } from 'common/types/pages';
import { formOffersSelector } from 'lib/features/createOrder/selectors';
import { useBaseOffers } from './useBaseOffers';
import {
  getExtendOfferForm, checkConflict, isHasConflict, getIsOfferAlreadyAdded, SLOT_COUNT,
} from '../helpers';
import {
  FormOffer, CheckConflictResult, FormOfferKey, FieldsBuildOrderForm, Slots,
} from '../types';

export interface AddOfferToFormProps {
  value?: string;
  data?: Offer | TeeOffer | null;
  field?: FormOfferKey;
  slots?: Slots | null;
  skipConflict?: boolean;
}

export interface AddSlotProps {
  data?: Offer | TeeOffer | null;
  offerId: string;
  field?: FormOfferKey;
  slotId: string;
  slotCount?: number;
}

export interface AddOptionProps {
  data?: Offer | TeeOffer | null;
  offerId: string;
  field?: FormOfferKey;
  optionId: string;
  optionCount?: number;
}

export interface DeleteSlotProps {
  offerId: string;
  field?: FormOfferKey;
}

export interface DeleteOptionProps {
  offerId: string;
  field?: FormOfferKey;
  optionId: string;
}

export interface DeleteOfferFromFormProps {
  value?: string;
  field?: FormOfferKey;
}

export interface UpdateFiltersProps {
  field?: FieldsBuildOrderForm;
  slots?: Slots | null;
  data?: Offer | TeeOffer | null;
}

// todo calc by backend
export const getDefaultSlots = async (offerInfo: Offer | TeeOffer | null): Promise<Slots | null> => {
  const isTEE = !!(offerInfo as TeeOffer)?.teeOfferInfo;
  const slots = offerInfo?.slots;
  if (!slots || !slots.length) return null;
  const res = (slots as (OfferSlot | TeeOfferSlot)[]).reduce((acc: OfferSlot | TeeOfferSlot, slot) => {
    const num = new BigNumber(slot.usage.price);
    return (num.comparedTo(new BigNumber(acc.usage.price)) === -1) ? slot : acc;
  }, slots[0]);
  return {
    slot: {
      id: res.id,
      count: isTEE ? SLOT_COUNT.TEE_OFFER : SLOT_COUNT.VALUE_OFFER,
    },
  };
};

export const useAddOfferToForm = () => {
  const [conflict, setConflict] = useState<{
    field: AddOfferToFormProps['field'],
    confictOffers: CheckConflictResult,
    value: FormOffer | null
  } | null>();
  const filters = useAppSelector(filtersSelector);
  const dispatch = useAppDispatch();
  const [getOffer] = useLazyGetOfferQuery();
  const [getTeeOffer] = useLazyGetTeeOfferQuery();
  const formOffers = useAppSelector(formOffersSelector);
  const [loadingAddOfferToForm, setLoadingAddOfferToForm] = useState(false);
  const { loading: loadingExtendedFormOffers, formOffers: extendedFormOffers } = useExtendedFormOffers(formOffers);
  const { loading: loadingBaseOffers, getOffers: getBaseOffers } = useBaseOffers();
  const showError = useCallback((message: string) => {
    dispatch(addErrorNotification(message));
  }, [dispatch]);

  const fetchOfferData = useCallback(async (offerId?: string, field?: FieldsBuildOrderForm): Promise<Offer | TeeOffer | null> => {
    if (!offerId || !field) return null;
    const props = { _id: offerId, mapTo: TIdSource.Blockchain };
    return field === FieldsBuildOrderForm.tee
      ? (await getTeeOffer(props).catch(() => null))?.data?.teeOffer as TeeOffer
      : (await getOffer(props).catch(() => null))?.data?.offer as Offer;
  }, [getTeeOffer, getOffer]);

  const updateFilters = useCallback((props: UpdateFiltersProps) => {
    const MINUTES_IN_HOURS = 60;
    const { field, data, slots } = props;
    if (!field || !data || !slots) return;
    // update lease for compute filters. Check minTimeMinutes
    if (field === FieldsBuildOrderForm.tee) {
      const minTimeMinutesFromSlot = ((data?.slots || []) as Array<OfferSlot | TeeOfferSlot>)
        .find(({ id }) => slots?.slot?.id === id)?.usage?.minTimeMinutes;
      const currentComputeFilters = filters?.[OffersPages.compute] as Form;
      const currentLease = (currentComputeFilters?.filters as FormFiltersCompute)?.[FiltersFields.lease]?.time;
      // get max minTimeMinutes from: current lease from filters and minTimeMinutes from tee slot
      const checkedLease = Math.max(currentLease ?? 0, Math.ceil((minTimeMinutesFromSlot ?? 0) / MINUTES_IN_HOURS));
      dispatch(
        updatePageFilters({
          page: OffersPages.compute,
          filters: {
            ...currentComputeFilters,
            filters: {
              ...currentComputeFilters?.filters,
              lease: {
                ...(currentComputeFilters?.filters as FormFiltersCompute)?.lease,
                ...(typeof checkedLease === 'number' ? { time: checkedLease } : {}),
              },
            },
          },
        }),
      );
    }
  }, [dispatch, filters]);

  const addOfferToForm = useCallback(async (props: AddOfferToFormProps) => {
    const {
      value, data: dataProp, field, slots: slotsProp, skipConflict = false,
    } = props || {};
    let data = dataProp;
    let error = '';
    if (!field || !value) {
      error = 'Offer id required';
    }
    if (!data) {
      data = await fetchOfferData(value, field);
    }
    if (!data) {
      error = 'Missing offer information';
    }
    const slots = slotsProp || await getDefaultSlots(data);
    if (!slots) {
      error = 'Slot required';
    }
    if (error) {
      throw new Error(error);
    }
    try {
      const { data: baseOffers } = await getBaseOffers(data as Offer);
      const formOffer: FormOffer = {
        value,
        base: await Promise.all(baseOffers.map(async (item) => {
          const slotsBaseOffer = await getDefaultSlots(item);
          if (!slotsBaseOffer) throw new Error('Slot for base offer required');
          return {
            value: item.id,
            slots: slotsBaseOffer,
          };
        })),
        slots,
      };
      if (!skipConflict) {
        const extendedFormOffer = getExtendOfferForm({ ...formOffer, data }, baseOffers);
        const confictOffers = checkConflict(field as FormOfferKey, extendedFormOffer, extendedFormOffers);
        if (isHasConflict(confictOffers)) {
          setConflict(() => ({
            field,
            value: formOffer,
            confictOffers,
          }));
          return;
        }
      }
      dispatch(updateOfferInFormAction({ key: field, value: formOffer }));
      updateFilters({ field, slots, data });
      dispatch(resetStepsAction());
    } catch (e) {
      throw new Error('Error fetching base offer');
    }
  }, [extendedFormOffers, dispatch, getBaseOffers, fetchOfferData, updateFilters]);

  const addOfferToFormCatched = useCallback(async (props: AddOfferToFormProps) => {
    setLoadingAddOfferToForm(true);
    try {
      await addOfferToForm(props);
    } catch (e) {
      showError((e as Error)?.message);
    } finally {
      setLoadingAddOfferToForm(false);
    }
  }, [showError, addOfferToForm]);

  const addSlot = useCallback(async (props: AddSlotProps) => {
    const {
      field, slotId, slotCount = SLOT_COUNT.VALUE_OFFER, offerId, data,
    } = props;
    const newSlot = {
      id: slotId,
      count: slotCount,
    };
    if (getIsOfferAlreadyAdded(offerId, field, formOffers)) {
      // add new offer, if offer is not defined
      dispatch(updateSlotInFormAction({ key: field, value: newSlot, offerId }));
    } else {
      await addOfferToFormCatched({
        value: offerId,
        slots: {
          slot: newSlot,
        },
        data,
        field,
      });
    }
  }, [addOfferToFormCatched, dispatch, formOffers]);

  const deleteSlot = useCallback(async (props: DeleteSlotProps) => {
    const { field, offerId } = props || {};
    // remove offer when deleting slot
    dispatch(deleteOfferFromFormAction({
      key: field,
      value: offerId,
    }));
  }, [dispatch]);

  // todo maybe later
  // const addOption = useCallback(async (props: AddOptionProps) => {
  //   const {
  //     field, optionId, optionCount = 1, offerId, data,
  //   } = props;
  //   if (getIsOfferAlreadyAdded(offerId, field, formOffers)) {
  //     dispatch(updateOptionInFormAction({ key: field, value: [optionId, optionCount], offerId }));
  //   } else {
  //     await addOfferToFormCatched({
  //       value: offerId,
  //       slots: {
  //         options: [{ id: optionId, count: optionsCount }],
  //       },
  //       data,
  //       field,
  //     });
  //   }
  // }, [addOfferToFormCatched, dispatch, formOffers]);

  // const deleteOption = useCallback(async (props: DeleteOptionProps) => {
  //   const { field, offerId, optionId } = props;
  //   dispatch(deleteOptionInFormAction({ key: field, offerId, optionId }));
  // }, [dispatch]);

  const resetConflict = useCallback(() => {
    setConflict(null);
  }, []);

  const replaceOffer = useCallback(() => {
    if (!conflict) return;
    const { confictOffers, field, value } = conflict || {};
    Object
      .entries(confictOffers)
      .forEach(([field, conflict]) => {
        dispatch(deleteOfferFromFormAction({
          key: field as FormOfferKey,
          value: Array.isArray(conflict) ? conflict.map((conflict) => conflict?.value) : conflict?.value,
        }));
      });
    dispatch(updateOfferInFormAction({ key: field, value }));
    resetConflict();
    dispatch(resetStepsAction());
  }, [dispatch, conflict, resetConflict]);

  const deleteOfferFromForm = useCallback((props: DeleteOfferFromFormProps) => {
    const { field, value } = props || {};
    dispatch(deleteOfferFromFormAction({ key: field, value }));
    dispatch(resetStepsAction());
  }, [dispatch]);

  const attentionModal = useMemo(() => {
    return <AttentionAddOrder show={!!conflict} onBack={resetConflict} onReplace={replaceOffer} onClose={resetConflict} />;
  }, [conflict, resetConflict, replaceOffer]);

  return {
    addOfferToForm: addOfferToFormCatched,
    resetConflict,
    replaceOffer,
    loading: loadingBaseOffers || loadingExtendedFormOffers || loadingAddOfferToForm,
    conflict,
    attentionModal,
    deleteOfferFromForm,
    deleteSlot,
    addSlot,
  };
};